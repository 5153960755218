.bl-team {
    background-color: $bgwhite;

    @include rem-calc-sl(padding-bottom, 200);

    .item {
        position: relative;
        .ctrexpandicon {
            cursor: pointer;

            position: absolute;
            right:0;
            top:rem-calc(35);
            @include breakpoint(medium down) {
                top:rem-calc(42);
            }
            @include breakpoint(small down) {
                top:rem-calc(20);
            }

            .icon {
                display: block;
                height: var(--expandiconsize);
                transition: transform 0.2s;
            }
        }

        &.expanded {
            .ctrexpandicon .icon {
                transform: rotate(45deg);
            }
        }    
    }
    .item:not(.expandable) .ctrexpandicon {
        display: none;
    }
    .item.expandable .itemcompact {
        cursor: pointer;
    }
    .itemcompact {
        display: flex;
        align-items: stretch;

        .ctrphoto {
            width: rem-calc(180);
            margin-right: rem-calc(30);

            display: flex;
            align-items: center;

            .photo {
                display: block;
                width: 100%;
                height: auto;
            }
        }

        .ctrname {
            border-top: 2px solid $black;

            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;

            @include typo-accordiontitle;

            .pname {
                margin-right:2em;
            }
        }
    }

    .itemdetail {
        display: none;

        border-top: 2px solid $black;

        margin-bottom: 3em;

        .photo {
            margin-top: 0.5em;
            display: block;
            width: rem-calc(300);
            max-width: 75vw;
            height: auto;
        }

        .pname {
            margin-top: 0.5em;

            @include typo-accordiontitle;
        }

        .text {
            margin-top: 0.5em;

            a {
                text-decoration: underline;
                text-decoration-thickness: 2px;
                text-underline-offset: 0.2em;
            }
        }
    }

    .item:last-child .ctrname {
        border-bottom: 2px solid $black;
    }
    .item:last-child .itemdetail {
        border-bottom: 2px solid $black;
    }

    @include breakpoint(small down) {
        .item {
            border-top: 2px solid $black;
        }
        .itemcompact {
            display:block;
            margin-top: 0.5em;
            margin-bottom: 0.5em;

            .ctrname {
                border:none;
            }
        }
    }
}