.bl-accordion {
    background-color: $green;
    color:$fullwhite;
    
    @include rem-calc-sl(padding-bottom, 200);

    .accitem {
        border-bottom:2px solid $fullwhite;

        .summary {
            display:flex;
            justify-content: space-between;

            //v1, immer zentriert (wie PDF)
            //align-items: center;

            //v2, oben
            align-items:flex-start;
            .icon {
                margin-top:rem-calc(12);
                @include breakpoint(medium down) {
                    margin-top:rem-calc(8);
                }
                @include breakpoint(small down) {
                    margin-top:rem-calc(7);
                }
            }

            cursor: pointer;

            margin-top:0.75em;
            margin-bottom:0.75em;

            .title {
                margin-bottom: 0;

                @include typo-accordiontitle;
                font-weight: 700;
            }
            .detailtitle {
                display:none;
            }
            .icon {
                height:var(--expandiconsize);
                display: block;
                transition: transform 0.2s;
            }
        }
        .details {
            display:none;

            margin-top: 0.5em;
            margin-bottom: 1em;

            p {
                margin-bottom: 0;
            }
            p + p {
                margin-top: 0.5em;
            }
        }

        &.expanded {
            .icon {
                transform: rotate(45deg);
            }
        }
    }
}