.bl-map {
    background-color: $bgwhite;

    @include rem-calc-sl(padding-bottom, 200);

    .cols {
        display:grid;
        grid-template-columns: 1fr 1fr;
        gap:rem-calc(50);

        @include breakpoint(medium down) {
            grid-template-columns: 1fr;
        }
    }
    .coltext {
        border-top:2px solid $black;
        border-bottom:2px solid $black;

        padding-top: 0.5em;
        padding-bottom: 0.5em;
    }
    iframe {
        border:none;
        display: block;
        width:100%;
        height:100%;
        @include breakpoint(medium down) {
            height:50vh;
            min-height: 300px;
        }
    }
}