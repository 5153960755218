.bl-referenzen {
    background-color: $bgwhite;

    @include rem-calc-sl(padding-bottom, 200);

    .items {
        display:grid;
        grid-template-columns: repeat(4, 1fr);
        @include rem-calc-sl(gap, 40);

        @include breakpoint(medium down) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include breakpoint(small down) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .item {
        background-color: $bglightgray;

        .logo {
            display:block;
            width:100%;
            height:auto;
        }
    }
}