footer {
  @include typo-footer;

  .ctrlr {
    //columns
    display:flex;
    gap:1em;
    @include breakpoint(medium) {
      justify-content: space-between;
    }
    @include breakpoint(small down) {
      flex-direction: column;
    }

    //border
    border-top:2px solid $black;
    padding:0.5em 0;
  }
}

