section {
    --baseunit: 1.5rem;
    @include breakpoint (medium down) {
        --baseunit: 1.25rem;
    }
    @include breakpoint (small down) {
        --baseunit: 1rem;
    }
    @for $i from -5 through 10 {
        &.pt_#{$i} {
            padding-top:calc(#{$i} * var(--baseunit));
        }
        &.pb_#{$i} {
            padding-bottom:calc(#{$i} * var(--baseunit));
        }

        &.mt_#{$i} {
            margin-top:calc(#{$i} * var(--baseunit));
        }
        &.mb_#{$i} {
            margin-bottom:calc(#{$i} * var(--baseunit));
        }
    }
}