body {
    --headerheight: #{rem-calc(190)};
}

.bl-video {
    video {
        display: block;
        width: 100%;
        margin-bottom: -1px; //firefox bug, weisse Linie

        /*V1, Hoehe einschraenken und object-fit. Personen werden moeglicherweise abgeschnitten
        @media (min-width:1024px) and (orientation: landscape) {
            height:calc(100vh - var(--headerheight));
            object-fit: cover;
            object-position: center center;
        }    
        */
    }

    //V2, Breite ab gewissen aspect-ratio limitieren
    background-color: $textcolor;
    video {
        max-width: rem-calc(1500);
        margin-left: auto;
        margin-right: auto;
    }
}