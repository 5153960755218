.bl-bigtext {
    background-color: $green;
    color:$fullwhite;

    @include rem-calc-sl(padding-top, 170, 0.3);
    @include rem-calc-sl(padding-bottom, 170, 0.3);

    .ctrbutton {
        display:flex;
        gap:1.5em;
        align-items: center;

        @include rem-calc-sl(margin-top, 260);

        .icon {
            height:var(--expandiconsize);
            display: block;
        }


        @include font-size-pt(30*0.5, 30);
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
    }

    .text {
        @include font-size-pt(22, 70);
        font-weight: 500;
    }
}