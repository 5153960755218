$black: rgb(68, 68, 68);
$white: rgb(242, 242, 242);
$fullwhite: #fff;

$textcolor: rgb(68, 68, 68);
$bgwhite: rgb(242, 242, 242);

$green: rgb(57, 108, 115);

$bglightgray: rgb(230, 230, 230);


body {
    --imagesgap: #{rem-calc(20)};
    @include rem-calc-sl(--expandiconsize, 50);

    
    //--gap1:2rem; 

    /*
    --borderwidth:2px;
    @media (min-device-pixel-ratio: 2) {
        --borderwidth:1.5px;
    }
    */
}