//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

@mixin typo-base {
  //Dynamic Font Size (mobile, desktop)
  @include font-size-pt(40*0.5, 40);
  font-weight: 500;
  letter-spacing: 0em;
  line-height:normal;
  color:$textcolor;
}

@mixin typo-accordiontitle {
  @include font-size-pt(50*0.5, 50);
}

body, header {
  font-family:"Poppins", sans-serif;
  @include typo-base;
}
a {
  text-decoration: none;
  color:inherit;
}
a:focus {
  outline: none;
}

@mixin typo-maintitle {
  @include font-size-pt(74*0.5, 74);
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 1.2;
}

p {
  margin:0 0 1em 0;
}
p:last-child {
  margin-bottom:0;
}

h1, h2, h3, h4 {
  margin:0 0 0.75em 0;
}
h1 {
  @include typo-maintitle;
  text-align: center;
  text-decoration: underline;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 0.14em;
  /*
  text-decoration-thickness: 5px;
  @include breakpoint(small down) {
    text-decoration-thickness: 3px;
  }
  */

  padding-top: 0.75em; //padding: no margin collapse
  margin-bottom: 1em;
}

@mixin typo-footer {
  @include font-size-pt(16, 24);
  font-weight: 500;
  letter-spacing: 0em;
  line-height:normal;
  color:$textcolor;
}