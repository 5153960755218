header {
  border-top: 10px solid $black;

  background-color: $bgwhite;

  @include rem-calc-sl(padding-top, 30);
  @include rem-calc-sl(padding-bottom, 70);

  .logo {
    @include rem-calc-sl(width, 300);
  }

  @include breakpoint (medium) {
    .ctrlm {
      display: flex;
      justify-content: space-between;
    }
  }

  .menu {
    text-transform: uppercase;

    @include font-size-pt(28*0.5, 28);
    font-weight: 700;

    a:hover {
      text-decoration: underline;
      text-underline-offset: 0.2em;
      text-decoration-thickness: 0.14em;
    }
  }
  .menu {
    display:flex;
    gap:rem-calc(40);
    /*
    @media (max-width:1499px) { 
      //ab hier Menupunkte nicht mehr untereinander, sondern nebeneinander
      gap:0;
      flex-direction: column;
    }
    */
    @media (max-width:849px) { 
      //ab hier keine Menupunkte mehr
      display:none;
      margin-top: 1em;
      a {
        display: block;
      }
    }
  }

}

header {
  position:sticky;
  top:0;
  z-index: 50;
}
header {
  .logo {
    transition:width 0.2s;
  }
}
header.opaque {
  //kompakte Version bei scroll
  @include rem-calc-sl(padding-bottom, 30);

  .logo {
    @include rem-calc-sl(width, 200);
  }

  @media (max-width:1499px) {
    .menu {
      display:none;
    }
  }
}